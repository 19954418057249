import React from 'react';

const Fr = ({width,height}:{width:number,height:number}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 2 2"
  >
    <circle cx="1" cy="1" r="1" fill="#FFFFFF" />
    <clipPath id="clipCircle">
      <circle cx="1" cy="1" r="1" />
    </clipPath>
    <g clipPath="url(#clipCircle)">
      <rect width="0.67" height="2" fill="#0055A4" />
      <rect width="0.67" height="2" x="0.67" fill="#FFFFFF" />
      <rect width="0.67" height="2" x="1.34" fill="#EF4135" />
    </g>
  </svg>
  );
};

export default Fr;
