import React from 'react'
import {components, translation} from "@context/Index"
import { prodDetailsBodyType } from '@utils/types/Product'
import { uploadUrl } from '@helpers/Helpers'


const ProductDetailsBody:React.FC<prodDetailsBodyType> = ({product, selectedVariant, setSelectedtVariant}) => {

  //product
  let {t} = translation.useTranslation().i18n
  let {Add2Cart, DescriptionElement, Carousel} = components
  let {child} = product
  let {label, productPrice} = selectedVariant
  let { ingredients, description, howToUse, productImages } = selectedVariant?.productTranslates[0]
  let { price, country } = productPrice[0]



  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-3 p-5">
        <div>
          <Carousel slides={productImages}/>
        </div>
        <div className="flex flex-col flex-grow gap-6 px-4">
          <h4 className="text-3xl text-brand-darker">{label}</h4>
          <h4 className="text-3xl text-brand-darker">{price + ' ' + country?.currency}</h4>
          {child?.length > 0 && <h4 className="text-lg font-bold underline">{t("variants")}</h4>}
          {child?.length > 0 && (
            <div className="flex rounded-md gap-3 flex-wrap">
              
              {child.map((item:any, index:number) =>{
                return(
                  <button onClick={() => setSelectedtVariant(item)} className="w-1/5 flex flex-col justify-between border rounded-md overflow-hidden ">
                    {/* <img
                      src={`${uploadUrl}${item?.productTranslates[0]?.productImages[0]?.image}`}
                      key={`Variant ${index}`}
                      alt={`Variant ${index}`}
                      className="flex flex-grow object-cover"
                    /> */}
                  <p className={`transition-color duration-300 p-1 w-full ${selectedVariant?.id === item?.id ? "bg-brand-darker text-gold" : "bg-white text-brand-darker"}`}>{item?.productTranslates[0]?.title}</p>
                  </button>
                )
              })}
            </div>
          )}
          <div className=" flex flex-col items-start">
            <Add2Cart/>
          </div>
          <DescriptionElement title={t('productDetails.ingredients')} content={ingredients}/>
          <DescriptionElement title={t('productDetails.description')} content={description}/>
          <DescriptionElement title={t('productDetails.how2Use')} content={howToUse}/>
        </div>
    </div>
  )
}

export default ProductDetailsBody