import { button } from '@utils/types/MenuTypes'
import { Link } from 'react-router-dom'


function MenuButtons({item}:{item:button}) {

  let {title, Icon, uri, action, inheritedClasses } = item
  let defaultClass = "bg-gold rounded-full w-10 h-10 flex flex-row justify-center items-center"
  return (
    uri 
    ? <Link to={uri} title={title || undefined} className={defaultClass} >
      <Icon/>
    </Link>
    : <button title={title || undefined} onClick={action ? action : () => null} className={`${defaultClass} ${inheritedClasses}`}>
      <Icon/>
    </button> 
  )
}

export default MenuButtons