import React, { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API } from '@api/API_INSTANCE';
import { Category, Country, Language } from '@interfaces/Interfaces';
import { useAuth } from "@context/AuthContext";
// import { useTranslation } from 'react-i18next';
import {translation} from "@context/Index"
import { GetCategories } from '@utils/api/CategoriesApi';


const BaseContextContext = createContext<{
    categories: any;
    // countries: Country[] | undefined;
    // languages: Language[] | undefined;
    userLanguage: string | undefined;
    isLoading: boolean;
    isError: boolean;
    isFetching: boolean;
    getCurrencySymbol: (currencyCode: string) => string;
}>({
    categories: [],
    // countries: [],
    // languages: [],
    userLanguage:undefined,
    isLoading: false,
    isError: false,
    isFetching: false,
    getCurrencySymbol: () => "",
});

export const useBaseContext = () => useContext(BaseContextContext);

export const BaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const { userToken } = useAuth();
    const {i18n} = translation.useTranslation();
    const currencySymbols: { [key: string]: string } = {
        MAD:"MAD",
        USD: "$",
        EUR: "€",
        GBP: "£",
        JPY: "¥",
        AUD: "$",
        CAD: "$",
        CHF: "Fr.",
        CNY: "¥",
        INR: "₹",
        RUB: "₽",
        BRL: "R$",
        MXN: "$",
        ZAR: "R",
        KRW: "₩",
        SEK: "kr",
        NOK: "kr",
        DKK: "kr",
        NZD: "$",
        SGD: "$",
        HKD: "$",
        PLN: "zł"
      };
      
      const getCurrencySymbol = (currencyCode: string): string => currencySymbols[currencyCode] || "";

    const {
        data: categories,
        isLoading: isLoadingCategories,
        isError: isErrorCategories,
        isFetching: isFetchingCategories,
    } = useQuery({
        queryKey: ['categories', i18n.language],
        queryFn: () => GetCategories("NULL", i18n.language),
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: true,
    });


    // const {
    //     data: countries,
    //     isLoading: isLoadingCountries,
    //     isError: isErrorCountries,
    //     isFetching: isFetchingCountries,
    // } = useQuery({
    //     queryKey: ['countries'],
    //     queryFn: () => API.get('/countries.json').then((res) => res.data),
    //     staleTime: 5 * 60 * 1000,
    //     refetchOnWindowFocus: true,
    // });


    // const {
    //     data: languages,
    //     isLoading: isLoadingLanguages,
    //     isError: isErrorLanguages,
    //     isFetching: isFetchingLanguages,
    // } = useQuery({
    //     queryKey: ['languages'],
    //     queryFn: () => API.get('/languages.json').then((res) => res.data),
    //     staleTime: 5 * 60 * 1000,
    //     refetchOnWindowFocus: true,
    // });

    
    const userLanguage = navigator.language 
    React.useEffect(()=>{ i18n.changeLanguage(userLanguage); },[userLanguage])
    // React.useEffect(()=>{ categories && console.log({categories}) },[categories])

    const isLoading = isLoadingCategories 
    // || isLoadingCountries || isLoadingLanguages;
    const isError = isErrorCategories 
    // || isErrorCountries || isErrorLanguages;
    const isFetching = isFetchingCategories 
    // || isFetchingCountries || isFetchingLanguages;

    const baseValues = { 
        categories, 
        // countries, 
        // languages, 
        userLanguage, 
        isLoading,
        isError, 
        isFetching ,
        getCurrencySymbol
    }
    return (
        <BaseContextContext.Provider value={baseValues}>
            {children}
        </BaseContextContext.Provider>
    );
};
