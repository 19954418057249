import { API } from "./API_INSTANCE"

export const getUser = (userToken: string) => {
    return API.get('/me', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
        }
    })
        .then((res) => res)
        .catch(err => { throw err })
}