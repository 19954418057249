import React, { useState, useEffect, useRef } from 'react';

function useNavBarScrollControl() {
  const [scrollUp, setScrollUp] = useState(true); // To control the navbar visibility
  const lastScrollY = useRef(0);

  useEffect(() => {
    let timeoutId:any = null;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
          // Scrolling down, hide the navbar
          setScrollUp(false);
        } else {
          // Scrolling up, show the navbar
          setScrollUp(true);
        }
        lastScrollY.current = currentScrollY;
      }, 100); // Debouncing to optimize performance
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures this runs once

  return scrollUp;
}

export default useNavBarScrollControl;
