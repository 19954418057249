import { getCategoriesType } from "@utils/types/api/Categories"
import { API } from "./API_INSTANCE"


export const GetCategories:getCategoriesType = (parent, languageCode) => {
    return API.get('/categories',{
        headers: {
            Accept:'application/json'
        },
        params:{
            parent,
            language_code : languageCode
        }
    })
        .then((res) => res.data)
        .catch((err) => { throw err })
}