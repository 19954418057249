import React from 'react'
import Icons from '@helpers/IconsHelper';
import {
  brandLogo, 
  components,
  translation,
  animation,
  hooks
} from '@context/Index'
import { useWindowWidthContext } from '@context/WindowWidthContext';
import { useAuth } from '@context/AuthContext';
import { useBaseContext } from '@context/baseContext';
import { Link } from 'react-router-dom';


function NavBar() {

  let { LuUser2,  MdFavoriteBorder, BiCartAdd , RxHamburgerMenu } = Icons
  let { Menu, ProfileButton } = components
  let {windowWidth} = useWindowWidthContext()
  let {t} = translation.useTranslation().i18n
  let {categories} = useBaseContext()
  let [expandResponsive, setExpandResponsive] = React.useState<boolean>(false)
  let scrollUp = hooks.useNavBarScrollControl()
  let {userToken} = useAuth()
  let currentScrollY = window.scrollY
  

  
  
  let MenuItems = [
    {name:t('navBar.home'), uri:"/"},
    {name:t('navBar.products'), 
      uri:"/products", 
      children:categories, 
      renderItem:(item:any) => <Link to="#">{item?.categoryTranslates[0]?.title}</Link>},
    {name:t('navBar.aboutUs'), uri:"/about-us"},
    {name:t('navBar.contactUs'), uri:"/contact"},
    {name:t('navBar.termsConditions'), uri:"/terms"}
  ]

  let menuButtons = [
    {
      title:t('navBar.menuButtons.menu'),
      Icon:() => <RxHamburgerMenu className={"text-brand-darker w-full h-full m-2"}/>, 
      action:()=>setExpandResponsive(!expandResponsive),
      inheritedClasses:"sm:hidden"
    },
    {
      title:userToken ? t('navBar.menuButtons.profile') : t('navBar.menuButtons.login'), 
      Icon:() => userToken ? <ProfileButton/> : <LuUser2 className="text-brand-darker w-full h-full m-2"/> , 
      uri:userToken ? "/profile" : "/login"
    },
    {
      title:t('navBar.menuButtons.favorits'),
      Icon:() => <MdFavoriteBorder className="text-brand-darker w-full h-full m-2"/>, 
      uri:"/favorits"
    },
    {
      title:t('navBar.menuButtons.cart'),
      Icon:() => <BiCartAdd className={"text-brand-darker w-full h-full m-2"}/>, 
      uri:"/cart"
    },
  ]

  React.useEffect(()=>{
    windowWidth >= 640 ? setExpandResponsive(true) : setExpandResponsive(false)
    }
  ,[windowWidth])

  

  return (
    <div className={`flex flex-wrap z-10 sticky top-0 transition-transform justify-between p-4 md:py-10 md:px-20 bg-white items-center duration-300 ${scrollUp ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="flex-grow flex justify-center items-center xl:flex-grow-0 p-2 mx-4">
        <img src={brandLogo} alt="Argori logo" className='w-28'/>
      </div>
      <Menu items={MenuItems} buttons={menuButtons} expandResponsive={expandResponsive}/>
    </div>
  )
}

export default NavBar