import { uploadUrl } from '@helpers/Helpers'
import {components, translation} from "@context/Index"
import React from 'react'
import { Link } from 'react-router-dom'


const CategoryTile = ({category}:{category:any}) => {

  let [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
  let imageURL = uploadUrl + category?.image
  let data = category?.categoryTranslates[0]
  let {SkeletonLoader} = components
  let {t} = translation.useTranslation().i18n

  // React.useEffect(()=>{category && console.log(category)},[category])

  return (
    <div className='flex transition-all flex-col items-center p-3 gap-2 w-full select-none cursor-default h-full' draggable={false}>
      <Link to={`/categories/${category?.categoryTranslates[0]?.slug}`} state={category} className="flex relative rounded-md overflow-hidden w-full h-full shadow-[0_0_15px_#258f671a]" >
        {!imageLoaded && <SkeletonLoader/>}
        <picture className={`${imageLoaded ? "block" : "hidden" } w-full`}>
        <img src={imageURL} onLoad={() => setImageLoaded(true)}  className={`w-full h-full object-cover`}/>
        </picture>
        <div className="absolute flex flex-col w-full h-full justify-center items-center p-10 bg-[rgba(0,0,0,.5)] transition-opacity opacity-0 hover:opacity-100 duration-500 text-center">
          <p className=" text-white font-bold">{t('totalProducts')}</p>
          <p className=" text-white font-bold">{category?.totalProducts}</p>
        </div>
      </Link>
      <h4 className="text-lg text-brand-darker font-bold text-center">
        {data?.title}
      </h4>
    </div>
  )
}

export default CategoryTile