import React from 'react'
import {components} from '@context/Index'

function Home() {

  let {Categories, Products} = components

  return (
    <div className='p-4 gap-32 flex flex-col'>
      <Products/>
      <Categories/>
    </div>
  )
}

export default Home