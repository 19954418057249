import React, { useEffect } from 'react'
import {components, translation} from "@context/Index"
import { useQuery } from '@tanstack/react-query'
import { getProducts } from '@utils/api/ProductsApi'
import { ENUMS } from '@helpers/ENUMS'

type productsType = { 
  categorySlug?:string,
  disablePrimaryQuery?:boolean
}

const Products:React.FC<productsType> = (props) => {
    let {categorySlug, disablePrimaryQuery = false} = props
    let {language, t} = translation.useTranslation().i18n
    let { ProductTile, Loading } = components

    let {data : products} = useQuery({
      queryKey:['products', language],
      queryFn: () => getProducts(language),
      enabled:!disablePrimaryQuery
    })

    let {data : categoryProducts} = useQuery({
      queryKey:['categoryProducts', language, categorySlug],
      queryFn: () => getProducts(language, categorySlug),
      enabled:disablePrimaryQuery
    })

    useEffect(()=>{ categoryProducts && console.log({categoryProducts})},[categoryProducts])

  return (
    <div className="container mx-auto">
      <h4 className="text-5xl p-10 text-center text-brand-darker">{t('products')}</h4>
      {(categoryProducts || products) ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
        {(categoryProducts || products)?.map((product:any) => {
          return(
            <ProductTile product={product} key={product?.productTranslates[0]?.slug}/>
          )
        })
       }    
      </div> : <Loading item={t('products')}/>}
    </div>
  )
}

export default Products