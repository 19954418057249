import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotAuthorized = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Navigate back
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
            <h1 className="text-6xl font-bold text-red-600 mb-4">403</h1>
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">Not Authorized</h2>
            <p className="text-lg text-gray-600 mb-8">You do not have permission to view this page.</p>
            <button
                onClick={handleGoBack}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
            >
                Go Back
            </button>
        </div>
    );
};

export default NotAuthorized;
