import { FormikErrors } from 'formik';
import React from 'react'

type LoginInputType = {
    label:string;
    errors:string | undefined
} & React.InputHTMLAttributes<HTMLInputElement>

const LoginInput:React.FC<LoginInputType> = ({label,errors, ...rest}) => {
    let {required} = rest
  return (
    <div className="flex flex-col w-full justify-center items-start gap-3 py-3">
        <div className="flex flex-row items-center gap-3">
            <label htmlFor="email" className="font-bold text-lg">{label}</label>
            {required && <p className="text-red-400"> * </p>}
        </div>
        <input {...rest}/>
        {errors && <p className="text-red-500 text-sm">{errors}</p>}
    </div>
  )
}

export default LoginInput