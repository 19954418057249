import React from 'react'
import {translation} from "@context/Index"
const Add2Cart = () => {
    
    let {t} = translation.useTranslation().i18n

  return (
    <button className="px-5 py-2 flex-grow text-xl bg-gold rounded-md transition-transform hover:scale-110 duration-500">{t('add2Cart')}</button>
  )
}

export default Add2Cart