import { uploadUrl } from '@helpers/Helpers'
import React from 'react'

type PropType = {
  selected: boolean
  index: number
  onClick: () => void,
  image:any
}

export const Thumb: React.FC<PropType> = (props) => {
  const { selected, index, onClick, image } = props

  return (
    <div
      className={'embla-thumbs__slide shadow-md shadow-brand-darker/20 rounded-lg overflow-hidden'.concat(
        selected ? ' embla-thumbs__slide--selected' : ''
      )}
    >
      <button
        onClick={onClick}
        type="button"
        className="embla-thumbs__slide__number overflow-hidden"
      >
        <img src={uploadUrl + image?.image} alt={image?.alt} className="w-full h-full object-cover" />
      </button>
    </div>
  )
}
