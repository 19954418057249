import React, { JSXElementConstructor, ReactElement } from 'react'
import { Helmet } from 'react-helmet-async';
import {components} from '@context/Index'

type LayoutType = {children:ReactElement<any, string | JSXElementConstructor<any>> | null}

const Layout:React.FC<LayoutType> = ({ children }) => {

    let {NavBar} = components

    return (
        <React.Fragment>
            <Helmet>
                <title>Argori</title>
                <meta name="description" content="argori dec" />
                <meta name="keywords" content={`argori`} />
                <meta name="author" content="Argori" />
                {/* <meta name="robots" content="index, follow" /> */}
                <meta property="og:image" content="%PUBLIC_URL%/" />
                <meta property="og:url" content="https://argori.com" />
                <meta property="og:title" content="Argori" />
                <meta property="og:description" content="argori desc" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Argori" />
                <meta property="og:locale" content="en_EN" />
            </Helmet>
            <main>
                <NavBar />
                {children}
                <div className='w-full h-[300px] bg-slate-400'></div>
            </main>
        </React.Fragment>
    )
}

export default Layout