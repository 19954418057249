import AuthProvider from "@context/AuthContext";
import { BaseProvider } from "@context/baseContext";
import { WindowWidthProvider } from "@context/WindowWidthContext";
import MainNavigation from "@navigation/MainNavigation";
import i18n from "@utils/translation/i18n";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";


function App() {

  const client = new QueryClient()
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <QueryClientProvider client={client}>
          <AuthProvider>
            <BaseProvider>
              <WindowWidthProvider>
                <HelmetProvider>
                  <MainNavigation/>
                </HelmetProvider>
              </WindowWidthProvider>
            </BaseProvider>  
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
