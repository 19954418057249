export const ENUMS = {
    COLORS:{
        BRAND:"#4c8c40",
        BRAND_DARK:"#214842",
        GOLD:"#efc368",
        BANNER_GOLD:"rgba(239, 195, 104, 0.18)"
    },
    PRODCUT:{
        TYPE:{
            PARENT : "PARENT",
            VARIANT : "VARIANT",
            FINAL : "FINAL",
        }
    }
}