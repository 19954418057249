import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from '@hooks/LocalStorage';
import { Authenticate } from '@api/AuthApi';
import { authInitialValuesTypes } from '@utils/types/AuthTypes';
import { getUser } from '@api/UserApi';
import {User} from "@interfaces/User"
import { authBody } from '@utils/types/api/Auth';

let initialValues: authInitialValuesTypes = {
    userToken: null,
    user: null,
    // roles: [],
    login: async () => { },
    logout: () => { },
    loading: true // Initialisez loading à true
}

let AuthContext = createContext(initialValues);

function AuthProvider({ children }: { children: ReactNode }) {

    let [userToken, setUserToken] = useLocalStorage('userToken', null);
    let [user, setUser] = React.useState<User | null>(null);
    // let [roles, setRoles] = React.useState<string[]>([]);
    let [loading, setLoading] = React.useState<boolean>(true); // Initialisez loading à true
    const navigate = useNavigate();

    let login = useCallback((body: authBody, rememberMe:boolean) => {
        setLoading(true);
        return Authenticate(body)
            .then((res: any) => {
                console.log("Authentication success", { body , rememberMe });
                setUserToken(res.data.token,rememberMe);
                setLoading(false);
            })
            .catch((err: any) => {
                console.error("Authentication failed", { err });
                setLoading(false);
            });
    },[userToken])

    let logout = useCallback(() => {
        setUserToken(null);
        setUser(null);
        // setRoles([]);
        // navigate('/', { replace: true });
    }, [userToken])

    useEffect(() => {
        if (userToken) {
            setLoading(true);
            getUser(userToken)
                .then((res: any) => {
                    setUser(res.data);
                    // const backendRoles = res.data.roles;
                    // if (backendRoles && backendRoles.length > 0) {
                    //     setRoles(backendRoles);
                    // } else {
                    //     setRoles([]);
                    // }
                    setLoading(false);
                    navigate('/',{replace:true})
                })
                .catch(err => {
                    console.error("Failed to get user data", err);
                    logout();
                    setLoading(false);
                });
        } else {
            setUser(null);
            // setRoles([]);
            setLoading(false);
        }
    }, [userToken]);

    let value = useMemo(() => ({
        userToken,
        user,
        // roles,
        login,
        logout,
        loading
    }), [user, userToken, loading]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);