import { DescriptionElementType } from '@utils/types/Product'
import React from 'react'

let DescriptionElement:React.FC<DescriptionElementType> = ({title, content}) => {
    return(
      <div className='flex flex-col gap-2'>
        <h5 className="text-lg font-bold underline">{title}</h5>
        <p>{content}</p>
      </div>
    )
  }

export default DescriptionElement