import { getProductsType, getProductType } from "@utils/types/api/Products"
import { API } from "./API_INSTANCE"


export const getProducts:getProductsType = ( languageCode, categorySlug) => {
    return API.get('/products',{
        headers: {
            Accept:'application/json'
        },
        params:{
            parent:"NULL",
            language_code : languageCode,
            "category.categoryTranslates.slug":categorySlug ?? null
        }
    })
    .then((res) => res.data)
    .catch((err) => { throw err })
}

export const getProduct:getProductType = ( languageCode, slug) => {
    return API.get('/products',{
        headers: {
            Accept:'application/json'
        },
        params:{
            "productTranslates.slug":slug,
            language_code : languageCode
        }
    })
    .then((res) => res.data)
    .catch((err) => { throw err })
}