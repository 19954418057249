import { AiFillHome } from 'react-icons/ai';
import { 
    BiPlus, 
    BiHome,
    BiChevronDown,
    BiCartAdd 
} from 'react-icons/bi';
import {
    BsCheckCircleFill,
    BsChatRightDots,
    BsChatLeftText,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsInstagram,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsTelephone
} from 'react-icons/bs';
import {
    CiCircleMore,
    CiEdit,
    CiFacebook,
    CiGrid2H,
    CiImageOff
} from 'react-icons/ci';
import {
    FaFacebookF,
    FaFilter,
    FaInstagram,
    FaLinkedinIn,
    FaShoppingBasket,
    FaTelegramPlane,
    FaYoutube,
    FaUser,
    FaHeart 
} from 'react-icons/fa';
import { FaLocationDot, FaEarthAfrica } from 'react-icons/fa6'
import {
    FiLogOut,
    FiSearch,
    FiShoppingBag,
    FiShoppingCart,
    FiChevronRight 
} from 'react-icons/fi';
import {
    GrContact,
    GrContactInfo,
    GrFavorite,
    GrFormClose,
} from 'react-icons/gr';
import {
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
} from 'react-icons/hi';
import { ImPhone } from 'react-icons/im';
import { 
    IoMdLocate,
    IoMdLogIn 
} from 'react-icons/io';
import {
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdOutlineManageAccounts,
    MdOutlineRateReview,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    MdFavoriteBorder
} from 'react-icons/md';
import { RiCoupon5Line, RiInstagramFill, RiMailOpenFill } from 'react-icons/ri';
import { RxDashboard, RxHamburgerMenu } from 'react-icons/rx';
import { LuUser2  } from "react-icons/lu";
import { IoHeartCircleOutline } from "react-icons/io5";

const Icons = {
    CiImageOff,
    FaHeart,
    IoHeartCircleOutline,
    FiChevronRight ,
    LuUser2,
    BiChevronDown,
    AiFillHome,
    BiPlus,
    BiHome,
    BsCheckCircleFill,
    BsChatRightDots,
    BsChatLeftText,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsInstagram,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsTelephone,
    CiCircleMore,
    CiEdit,
    CiFacebook,
    CiGrid2H,
    FaFacebookF,
    FaFilter,
    FaInstagram,
    FaLinkedinIn,
    FaLocationDot,
    FaEarthAfrica,
    FaShoppingBasket,
    FaTelegramPlane,
    FaYoutube,
    FiLogOut,
    FiSearch,
    FiShoppingBag,
    FiShoppingCart,
    GrContact,
    GrContactInfo,
    GrFavorite,
    GrFormClose,
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
    ImPhone,
    IoMdLocate,
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdOutlineManageAccounts,
    MdOutlineRateReview,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    RiCoupon5Line,
    RiInstagramFill,
    RiMailOpenFill,
    RxDashboard,
    IoMdLogIn,
    MdFavoriteBorder,
    BiCartAdd,
    RxHamburgerMenu,
    FaUser
};

export default Icons;
