import React, { useEffect } from 'react'
import { useBaseContext } from '@context/baseContext'
import {components, translation, hooks} from "@context/Index"

type categoriesType = {
  importedCategories?:any,
  selectedCategory?:any

}
const Categories:React.FC<categoriesType> = ({importedCategories}) => {

  let {categories, isLoading} = useBaseContext()
  let {Loading, CategoriesCarousel} = components
  let {t} = translation.useTranslation().i18n
  const ref = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = hooks.useDraggable(ref)
  console.log({importedCategories})
  
  return (
    <div className="container mx-auto">
      {!importedCategories && <h4 className="text-5xl p-10 text-center text-brand-darker">{t(importedCategories ? 'subCategories' : 'categories')}</h4>}
      <div {...events} ref={ref}>
        {(importedCategories || categories) && <CategoriesCarousel slides={importedCategories || categories} options={{loop:true}} size={importedCategories && "small"}/>}
      </div>
      {isLoading && <Loading item={t('categories')}/>}
    </div>
  )
}

export default Categories