import React from 'react'
import { components, translation } from "@context/Index"
import { useLocation, useParams} from 'react-router-dom'
import { ENUMS } from '@helpers/ENUMS'
import { useQuery } from '@tanstack/react-query'
import { getProduct } from '@utils/api/ProductsApi'

const ProductDetails:React.FC = () => {
    let { Header, Breadcrumbs, ProductDetailsBody, Loading } = components
    let params = useParams()
    let {slug} = params
    const location = useLocation()
    const [product, setProduct] = React.useState(location?.state)
    const [selectedVariant, setSelectedVariant] = React.useState(location?.state?.type === ENUMS.PRODCUT.TYPE.FINAL ? location?.state : location?.state?.child[0])

    const {language, t} = translation.useTranslation().i18n
    const initialRender = React.useRef(true); // Ref to track if this is the first render

    const { data, isLoading } = useQuery({
      queryKey:['product',language],
      queryFn:() => getProduct(language,slug as string),
      enabled:!initialRender.current || !product
    })

    React.useEffect(() => { 
      initialRender.current = false
      if(data && !selectedVariant){
        setProduct(data[0])
        setSelectedVariant(data[0].type === ENUMS.PRODCUT.TYPE.FINAL ? data[0] : data[0].child[0])
      }
    } ,[data])

  if(isLoading){
    return(
      <Loading item={t('product')}/>
    )
  }

  return (
    product && <div>
      <Header title={selectedVariant?.label} background={ENUMS.COLORS.BANNER_GOLD} />
      <Breadcrumbs/>
      <ProductDetailsBody product={product} selectedVariant={selectedVariant} setSelectedtVariant={setSelectedVariant}/>
    </div>
  )
}

export default ProductDetails