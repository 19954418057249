import React from 'react'
import {components, translation, pngs} from "@context/Index"


function Login() {
  let {t} = translation.useTranslation().i18n
  let {Header, Breadcrumbs, LoginForm } = components
  let {login} = pngs
  
  return (
    <>
      <Header title={t('login.login')} subtitle={t('login.subtitle')} imageUrl={login}/>
      <Breadcrumbs/>
      <LoginForm/>
    </>
  )
}

export default Login