import { useState } from "react";


export const useLocalStorage = ( keyName:string, defaultValue:any ) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const localValue = window.localStorage.getItem(keyName);
      const sessionValue = window.sessionStorage.getItem(keyName);
      if (localValue) {
        return JSON.parse(localValue);
      } else if (sessionValue) {
        return JSON.parse(sessionValue)
      }else{
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue:any, rememberMe:boolean) => {
    try {
     if(rememberMe){
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
     }else{
      window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
     }
    } catch (err) {
      console.error('Error setting local storage : ', err);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};