import React from 'react';
import { motion } from 'framer-motion';
import { ENUMS } from '@helpers/ENUMS';
type ActivityIndicatorType = {
    size:number,
    color:string
}

const ActivityIndicator: React.FC<ActivityIndicatorType> = ({size, color}) => {
    return (
          <motion.div
            className={`border-4 border-t-4 border-gray-300 rounded-full`}
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: "linear",
            }}
            style={{
              borderTopColor:color, // Customize your color here
              width:size+"px",
              height:size+"px"
            }}
          />
      );
};

export default ActivityIndicator;
