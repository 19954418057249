import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@routes/ProtectedRoutes';
import {pages, hooks, translation} from "@context/Index"
function MainNavigation() {

  let {
    Home,
    AboutUs,
    Contact,
    Profile,
    NotAuthorized,
    Login,
    ProductDetails,
    ProductsPage
  } = pages

  let {useLocalStorage} = hooks

  let [userToken] = useLocalStorage("userToken",null)
  let {language} = translation.useTranslation().i18n
  
  return (

    <Routes>
      
      {/* <Route path='/not-authorized' element={<NotAuthorized />} /> */}

      {/* <Route element={<HomeRoute />}>
      </Route> */}
      <Route path='/' element={<ProtectedRoute />}>
        <Route path='/' element={<Home />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={userToken ? <Navigate to="/" /> : <Login />} />
        <Route path="/categories/:slug" element={<ProductsPage/>} />
        <Route path="/products/:slug" element={<ProductDetails/>} />
        {/* <Route path='categories' element={<CategoryList />} /> */}
      </Route>
    </Routes>
  );
}

export default MainNavigation;