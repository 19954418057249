import React from 'react'
import { animation, svgs, components, translation } from '@context/Index'
import Icons  from '@helpers/IconsHelper'
import { LangsType } from '@utils/types/LangSelectTypes'

function LangSelect() {

  let [expanded,setExpanded] = React.useState<boolean>(false)
  let {language} = translation.useTranslation().i18n
  let {AnimatePresence} = animation
  let {FlagsDropdown} = components

  let {
    BiChevronDown
  } = Icons

  let {Fr,Uk} = svgs
 

  let Langs:LangsType = {
    "fr":()=><Fr width={25} height={25}/>,
    "en":()=><Uk width={25} height={25}/>,
    "default": ()=><Uk width={25} height={25}/>,
  }

  const renderFlag = Langs[language?.split('-')[0]] || Langs["default"]
  
  return (
    <button 
      className="bg-gold rounded-full p-2 flex items-center gap-2 relative"
      onMouseLeave={()=>setExpanded(false)}
      onMouseEnter={()=>setExpanded(true)}
      onClick={()=>setExpanded(!expanded)}
    >
      {renderFlag()}
      <BiChevronDown/>
      <AnimatePresence>
        {expanded && <FlagsDropdown Langs={Langs} key="dropdown"/>}
      </AnimatePresence>
    </button>
  )
}

export default LangSelect