import { animation, svgs, translation } from '@context/Index'
import { LangsType } from '@utils/types/LangSelectTypes'
import React from 'react'

let FlagsDropdown = ({Langs}:{Langs:LangsType}) =>{

    const {useAnimate, usePresence} = animation
    const [scope,animate] = useAnimate()
    const [isPresent, safeToRemove] = usePresence() 
    const {changeLanguage} = translation.useTranslation().i18n

    const handleChangeLanguage = (langCode:string) =>{
      changeLanguage(langCode)
    }

    React.useEffect(() => {
      if (isPresent) {
        const enterAnimation = async () => {
          await animate(scope.current, { opacity: 1 }, { duration:.3 })
        }
        enterAnimation()
      } else {
        const exitAnimation = async () => {
          await animate(scope.current, { opacity: 0 }, { duration:.3 })
          safeToRemove()
        }
        exitAnimation()
      }
    }, [isPresent])


    return(
      <div 
      className={`flex flex-col opacity-0 gap-2 absolute z-10 top-full my-1 bg-white border p-3 rounded-full overflow-hidden}`}
      ref={scope}>
        <ul className="flex flex-col gap-4">
        {Object.keys(Langs).map((langKey) =>{
          if(langKey !== "default"){
            return (
              <li key={langKey} onClick={()=>{handleChangeLanguage(langKey)}}>
                {Langs[langKey]()}
              </li>
            )
          }
        } )}
        </ul>
      </div>
    )
}

export default FlagsDropdown