import React from 'react'
import { headerType } from '@utils/types/HeaderType'


const Header : React.FC<headerType> = ({title,subtitle,background = "rgba(156,163,175,.18)", imageUrl}) => {
  return (
    <header 
    className={`flex flex-col justify-center items-center text-center p-32 gap-6 overflow-hidden `}
    style={
      {background: `${imageUrl ? `url(${imageUrl}) right no-repeat`: "" }  ${background}`, backgroundSize:"contain"}
    }
    >
      {title && <h3 className="text-7xl font-bold text-brand-darker">{title}</h3>}
      {subtitle && <p className="opacity-60">{subtitle}</p>}
    </header>
  )
}

export default Header