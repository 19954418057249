import ContentLoader, { IContentLoaderProps } from "react-content-loader"

const SkeletonLoader = (props: IContentLoaderProps) => (
    <ContentLoader 
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 100 100 "
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      preserveAspectRatio="none"
      {...props}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" /> 
    </ContentLoader>
)

export default SkeletonLoader