import React from 'react'
import {  uploadUrl } from '@helpers/Helpers'
import { components, translation} from "@context/Index"
import Icons from '@helpers/IconsHelper'
import { Link } from 'react-router-dom'
import { ENUMS } from '@helpers/ENUMS'

const ProductsTile = ({ product }:{ product:any }) => {


  let finalProduct = product?.type === ENUMS.PRODCUT.TYPE.FINAL ? product : product?.child[0]
  let {label} = product
  let { productTranslates, productPrice } = finalProduct
  let {image} = productTranslates[0]
  let { price, country } = productPrice[0]
  let imageURL = uploadUrl + productTranslates[0]?.image 
  let [imageLoaded, setImageLoaded] = React.useState<boolean>(false)
  let { SkeletonLoader, Add2Cart } = components
  let { FaHeart, CiImageOff } = Icons
  let {t} = translation.useTranslation().i18n 


  return (
    <Link to={`/products/${product?.productTranslates[0].slug}`} state={product} className='flex flex-col rounded-xl shadow-[0_0_15px_#258f671a] overflow-hidden justify-between relative bg-white'>
      <div className="flex h-96 relative overflow-hidden justify-center items-center">
        {(!imageLoaded && image ) && <SkeletonLoader/>}
        { image 
        ? <img src={imageURL} onLoad={() => setImageLoaded(true)}  className={`${imageLoaded ? "block" : "hidden" } h-full object-cover`}/> 
        : <CiImageOff size={'50%'} className={`${imageLoaded ? "block" : "hidden" } h-full opacity-10`}/>
        }
      </div>
      <div className="p-7 text-center flex flex-col gap-8 w-full">
      <p className="text-lg text-brand-darker font-bold"> { label } </p>
      <p className="text-lg text-brand-darker font-bold"> { price + ' ' + country?.currency } </p>
      <Add2Cart/>
      </div>
       <div className="absolute top-5 right-5 z-1 cursor-pointer border-2 p-2 border-gold rounded-full hover:bg-gold transition-colors duration-300 group">
        <FaHeart size={20} className="transition-colors text-gold rounded-full group-hover:text-white"/>
       </div>
    </Link>
  )
}

export default ProductsTile