import { API } from "./API_INSTANCE"
import { authBody } from "@utils/types/api/Auth"

export const Authenticate = (body:authBody) => {
   return API.post('/login',body,{
        headers: {
            Accept:'application/json'
        }
    })
    .then( (res) => res )
    .catch( err => { throw err } )
}