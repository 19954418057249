import React, { useState } from 'react';
import { uploadUrl } from '@helpers/Helpers';
import { ProductSliderType } from '@utils/types/Product';
import Icons from '@helpers/IconsHelper';
import { Link } from 'react-router-dom';


const ProductSlider: React.FC<ProductSliderType> = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {FiChevronRight} = Icons
  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  React.useEffect(()=>{

    const autoSlide = setInterval(() => {
      handleNext()
    }, 3000)

    return ()=> clearInterval(autoSlide)
  },[])

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div
        className="flex transition-transform h-full duration-500 ease-in-out"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {images.map((image: any, index: number) => (
          <Link to={uploadUrl + image?.image} target='_blank' key={index} className="min-w-full box-border">
            <img src={uploadUrl + image?.image} alt={image?.alt} className="w-full h-full  object-cover transition-transform duration-300 hover:scale-110" />
          </Link>
        ))}
      </div>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 bg-black bg-opacity-10 rotate-180 rounded-full text-white border-none p-2 cursor-pointer z-10 left-2"
        onClick={handlePrev}
      >
        <FiChevronRight size={25} color='#fff'/>
      </button>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 bg-black rounded-full bg-opacity-10 text-white border-none p-2 cursor-pointer z-10 right-2"
        onClick={handleNext}
      >
        <FiChevronRight size={25} color='#fff'/>
      </button>
    </div>
  );
};

export default ProductSlider;
