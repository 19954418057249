//Custom hooks
import { useLocalStorage } from "@hooks/LocalStorage";
import useClickOutside  from "@hooks/useClickOutside";
import useClickInside from "@hooks/useClickInside";
import useNavBarScrollControl from "@hooks/NavBarScrollControl";

//Animations
import { useAnimate, AnimatePresence, usePresence, useTransform, useMotionValue } from "framer-motion";

//Formik
import { useFormik } from "formik";

//Translation i18n
import {useTranslation} from "react-i18next"

//Drag scroll hook
import { useDraggable } from "react-use-draggable-scroll";

//Svgs
import Fr from "@resources/svg/flags/Fr"
import Uk from "@resources/svg/flags/Uk"

//Pngs
import login from "@resources/pngs/banners/login/login.png"

//Components
import MenuItem from '@components/navBar/MenuItem'
import MenuButton from '@components/navBar/MenuButton'
import LangSelect from '@components/navBar/LangSelect'
import Menu from '@components/navBar/Menu'
import DropDownMenu from '@components/navBar/DropDownMenu'
import FlagsDropdown from '@components/navBar/FlagsDropdown'
import MenuItems from '@components/navBar/MenuItems'
import NavBar from '@components/navBar/NavBar';
import Loading from "@components/loading/Loading";
import Layout from "@layouts/Layout"
import Header from "@components/header/Header" 
import Breadcrumbs from "@components/breadcrumbs/Breadcrumbs"
import LoginForm from "@components/login/LoginForm";
import LoginInput from "@components/login/LoginInput";
import ProfileButton from "@components/profile/ProfileButton";
import CategoryTile from "@components/categories/CategoryTile";
import Categories from "@components/categories/Categories";
import ActivityIndicator from '@components/loading/ActivityIndicator'
import SkeletonLoader from "@components/loading/SkeletonLoader"
import Products from "@components/products/Products";
import ProductTile from "@components/products/ProductTile";
import ProductDetailsBody from "@components/products/ProductDetailsBody";
import Add2Cart from "@components/products/Add2Cart";
import DescriptionElement from "@components/products/DescriptionElement";
import ProductSlider from "@components/products/ProductSlider";
import {Thumb} from "@components/carousel/productsCarousel/CarouselThumbsButton"
import Carousel from "@components/carousel/productsCarousel/Carousel";
import {CategoriesCarousel} from "@components/carousel/categoriesCarousel/CategoriesCarousel"
//Pages
import Home from '@pages/home/Home';
import Contact from "@pages/contact/Contact";
import AboutUs from '@pages/aboutUs/AboutUs';
import Profile from "@pages/profile/Profile";
import NotAuthorized from '@pages/notAuthorized/NotAuthorized';
import Login from "@pages/login/Login";
import ProductDetails from "@pages/product/ProductDetails"
import ProductsPage from "@pages/products/Products";


//!exports must stay below imports

//Images
export { default as brandLogo } from "@resources/logo.png"

export const hooks = {
    useLocalStorage,
    useClickOutside,
    useClickInside,
    useNavBarScrollControl,
    useDraggable
}

export const svgs = {
    Fr,
    Uk
}

export const pngs = {
    login
}

export const formikHook = {
    useFormik
}

export const translation = {
    useTranslation
}

export const components = {
    CategoriesCarousel,
    Carousel,
    Thumb,
    ProductSlider,
    DescriptionElement,
    Add2Cart,
    ProductDetailsBody,
    Products,
    ProductTile,
    SkeletonLoader,
    ActivityIndicator,
    Categories,
    CategoryTile,
    ProfileButton,
    MenuItem,
    MenuItems,
    MenuButton,
    LangSelect,
    Menu,
    DropDownMenu,
    FlagsDropdown,
    NavBar,
    Layout,
    Loading,
    Header,
    Breadcrumbs,
    LoginForm,
    LoginInput
}

export const pages ={
    ProductsPage,
    ProductDetails,
    Home,
    AboutUs,
    Contact,
    Profile,
    NotAuthorized,
    Login
}

export const animation = {
    useAnimate,
    AnimatePresence,
    usePresence,
    useTransform,
    useMotionValue
}

