import React, { useCallback, useEffect, useState } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import useembla2Carousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import './css/base.css'
import './css/embla.css'
import {components} from "@context/Index"
type PropType = {
  slides: number[]
  options?: EmblaOptionsType,
  size?:"defaut" | "small"
}

export const CategoriesCarousel: React.FC<PropType> = (props) => {
  const { slides, options, size } = props
  const [embla2Ref, embla2Api] = useembla2Carousel(options, [
    AutoScroll({ playOnInit: true, stopOnInteraction: false, speed:.5 }),
  ])
  const [isPlaying, setIsPlaying] = useState(false)
  const {CategoryTile} = components

  const onButtonAutoplayClick = useCallback(
    (callback: () => void) => {
      const autoScroll = embla2Api?.plugins()?.autoScroll
      if (!autoScroll) return

      const resetOrStop =
        autoScroll.options.stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop

      resetOrStop()
      callback()
    },
    [embla2Api]
  )

  const toggleAutoplay = useCallback(() => {
    const autoScroll = embla2Api?.plugins()?.autoScroll
    if (!autoScroll) return

    const playOrStop = autoScroll.isPlaying()
      ? autoScroll.stop
      : autoScroll.play
    playOrStop()
  }, [embla2Api])

  useEffect(() => {
    const autoScroll = embla2Api?.plugins()?.autoScroll
    if (!autoScroll) return

    setIsPlaying(autoScroll.isPlaying())
    embla2Api
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()))
  }, [embla2Api])

  return (
    <div className="parent">
      <div className="embla2">
      <div className="embla2__viewport" ref={embla2Ref}>
        <div className="embla2__container">
          {slides.map((slide:any, index:number) => (
            <div className={size === "small"? "embla2__slide embla2__slide_small":"embla2__slide"} key={index}>
              <div className={size === "small"?"embla2__slide__number_small":"embla2__slide__number"}>
                <CategoryTile category={slide} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  )
}

